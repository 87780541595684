import "./App.css";
import Header from "./Components/Header/Header";
import TextGenerator from "./TextGenerator/Textgenerator";
//c99aae8a5d70f9a590011dc5cc5d565d4a565631
function App() {
  return (
    <div className="App">
      <Header></Header>
      <TextGenerator></TextGenerator>
    </div>
  );
}

export default App;
